import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {concatMap, forkJoin, Observable, of, Subject, tap} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {DBService} from './db.service';
import {HttpWrapperService} from './http-wrapper.service';
import {NotificationService} from './notification.service';
import {EntityResponse, IIBricksEntity} from '../interfaces';
import {deepCopy, generateUuid} from '../utilities';
import {RouteEnum} from '../enums/route.enum';
import {SYNC_TASK_STORES} from '../constants/sync-task-stores';

@Injectable({
  providedIn: 'root'
})
export class SyncService {
  private readonly autoSyncFinishedSubject = new Subject<void>();

  constructor(
    private dbService: DBService,
    private http: HttpWrapperService,
    private notificationService: NotificationService,
    private router: Router
  ) {}

  public getAutoSyncFinished(): Observable<void> {
    return this.autoSyncFinishedSubject.asObservable();
  }

  /**
   * Adds syncTask into specific store or updates existing one.
   * Adds unid, created and storeName fields which should be removed before POST to server.
   * @param storeName
   * @param data
   */
  public addOrUpdateSyncTask<T>(storeName: string, data: T): Observable<T> {
    const syncTask: IIBricksEntity = {...data};
    if (!syncTask.unid) {
      syncTask.unid = generateUuid();
    }
    if (!syncTask.created) {
      syncTask.created = new Date();
    }
    if (!syncTask.storeName) {
      syncTask.storeName = storeName;
    }
    return this.dbService
      .update<T>(storeName, syncTask as T)
      .pipe(tap(() => this.notificationService.openSnackbar('NOTIFICATIONS.SAVED-IN-SYNC-QUEUE')));
  }

  public sync(isAutoSync?: boolean): Observable<Array<IIBricksEntity | IIBricksEntity[]> | null> {
    return this.dbService.getSyncTasks().pipe(
      switchMap(syncTasks => {
        if (syncTasks.length) {
          this.notificationService.openSnackbar({
            message: 'NOTIFICATIONS.SYNCHRONIZATION-STARTED',
            config: {duration: 0}
          });
          const syncSources = syncTasks.map(i => this.getApiPostSource(deepCopy(i)));
          return this.synchronizationQueue(syncSources).pipe(
            switchMap(res => this.syncHandler(res, syncTasks, isAutoSync))
          );
        }
        return of(null);
      })
    );
  }

  private synchronizationQueue(sources: Observable<EntityResponse>[]): Observable<EntityResponse[]> {
    let current = 0;
    const results: EntityResponse[] = [];

    return new Observable<EntityResponse[]>(obs => {
      const queue = new Subject<Observable<EntityResponse>>();
      queue.pipe(concatMap((source: Observable<EntityResponse>) => source)).subscribe(res => {
        results.push(res);
        current += 1;
        if (current === sources.length) {
          obs.next(results);
          obs.complete();
        }
      });

      sources.forEach(i => queue.next(i));
    });
  }

  private syncHandler(
    responses: EntityResponse[],
    syncTasks: IIBricksEntity[],
    isAutoSync?: boolean
  ): Observable<Array<IIBricksEntity | IIBricksEntity[]>> {
    const updateSources: Array<Observable<IIBricksEntity> | Observable<IIBricksEntity[]>> = [];

    responses.forEach((i, x) => {
      const syncTask = syncTasks[x];
      const {unid, storeName} = syncTask;

      if (i?.data?.unid) {
        updateSources.push(
          this.dbService.delete<IIBricksEntity>(storeName, unid),
          this.dbService.update<IIBricksEntity>(storeName, i.data)
        );
      } else if (i?.meta?.error_message) {
        syncTask.errorMessage = i.meta.error_message;
        updateSources.push(this.dbService.update<IIBricksEntity>(storeName, syncTask));
      }
    });

    return forkJoin(updateSources).pipe(
      tap(() => {
        if (isAutoSync) {
          this.autoSyncFinishedSubject.next();
        }
        this.openSnackbar(responses, syncTasks);
      })
    );
  }

  private openSnackbar(responses: EntityResponse[], syncTasks: IIBricksEntity[]): void {
    const successLength = responses.filter(i => i?.data?.unid).length;
    let message = 'NOTIFICATIONS.SYNCHRONIZATION';
    const withErrors = syncTasks.length > successLength;
    message += successLength ? `-FINISHED${withErrors ? '-WITH-ERRORS' : ''}` : '-FAILED';
    const action = withErrors ? (this.router.url.includes(RouteEnum.SyncTasks) ? '' : 'STATUSES.OPEN') : '';
    const ref = this.notificationService.openSnackbar({message, action, config: {duration: 10000}});
    const sub = ref.onAction().subscribe(() => {
      sub.unsubscribe();
      this.router.navigateByUrl(`/${RouteEnum.Organization}/${RouteEnum.SyncTasks}`);
    });
  }

  private getApiPostSource(entity: IIBricksEntity): Observable<EntityResponse> {
    const url = SYNC_TASK_STORES[entity.storeName]?.apiPostUrl;
    if (url) {
      delete entity.unid;
      delete entity.storeName;
      delete entity.errorMessage;
      return this.http.post<EntityResponse>(`${environment.ibricksApiV3}/${url}`, entity);
    }
    return of(null);
  }
}
