import {ISyncTaskStoreDictionaryItem} from '../../shared';
import {StoreEnum} from '../enums/store-enum';
import {RouteEnum} from '../enums/route.enum';

export const SYNC_TASK_STORES: {[storeName: string]: ISyncTaskStoreDictionaryItem} = Object.freeze({
  [StoreEnum.Persons]: {
    label: 'SIDENAV.CONTACTS.PERSONS',
    value: RouteEnum.Contacts,
    apiPostUrl: 'contacts/person'
  },
  [StoreEnum.Companies]: {
    label: 'SIDENAV.CONTACTS.COMPANIES',
    value: RouteEnum.Companies,
    apiPostUrl: 'contacts/organizations'
  },
  [StoreEnum.Incidents]: {
    label: 'SIDENAV.INCIDENT-REPORTS.INCIDENTS',
    value: RouteEnum.Incidents,
    apiPostUrl: 'incidents'
  },
  [StoreEnum.Tasks]: {
    label: 'SIDENAV.TASKS.TITLE',
    value: RouteEnum.Tasks,
    apiPostUrl: 'tasks'
  }
});
