import {Injectable} from '@angular/core';
import {delay, forkJoin, Observable, of, switchMap, tap} from 'rxjs';
import {first} from 'rxjs/operators';
import {CommonUtilitiesService} from 'utilities';
import {IOfflineData, IResponse} from '../interfaces';
import {HttpWrapperService} from './http-wrapper.service';
import {environment} from '../../../../environments/environment';
import {DBService} from './db.service';
import {SyncService} from './sync.service';
import {StorageService} from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {
  public online: boolean;

  constructor(
    private commonUtilitiesService: CommonUtilitiesService,
    private http: HttpWrapperService,
    private dbService: DBService,
    private syncService: SyncService,
    private storageService: StorageService
  ) {
    this.online = navigator?.onLine;
  }

  /**
   * Uses getConnection observable to execute synchronization when state changes from offline to online.
   */
  public autoSync(): Observable<unknown> {
    return this.getConnection().pipe(
      delay(3000),
      switchMap(online => (online ? this.syncService.sync(true) : of(null)))
    );
  }

  /**
   * Provides connection state with side effect to store current state.
   */
  getConnection(): Observable<boolean> {
    return this.commonUtilitiesService.getConnection().pipe(tap(online => (this.online = online)));
  }

  /**
   * Gets offline data to store in indexedDb stores (prod).
   */
  getOfflineData(): Observable<unknown> {
    this.storageService.removeOldStore();

    if (this.storageService.isOfflineDataSet || !environment.production) {
      return of(null);
    }

    return this.http.get<IResponse<IOfflineData>>(`${environment.ibricksApiV3}/services/offline`).pipe(
      first(),
      switchMap(res => (res?.data ? this.setOfflineData(res.data) : of(null)))
    );
  }

  public setOfflineData(data: IOfflineData): Observable<unknown> {
    const keys = data ? Object.keys(data) : [];
    if (keys.length) {
      const clearSources = keys.map(i => this.dbService.clearStoreEntities(i));
      const updateSources = keys.map(i => this.dbService.bulkPut(i, data[i]));
      return forkJoin(clearSources)
        .pipe(switchMap(() => forkJoin(updateSources)))
        .pipe(tap(() => this.storageService.setOfflineDataMarker()));
    }
    return of(null);
  }
}
